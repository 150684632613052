import { render, staticRenderFns } from "./top-nav.vue?vue&type=template&id=7d80b8aa&scoped=true&"
import script from "./top-nav.vue?vue&type=script&lang=js&"
export * from "./top-nav.vue?vue&type=script&lang=js&"
import style0 from "./top-nav.vue?vue&type=style&index=0&id=7d80b8aa&prod&scoped=true&lang=scss&"
import style1 from "./top-nav.vue?vue&type=style&index=1&id=7d80b8aa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@4.3.0_vue-template-compiler@2.6.12_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d80b8aa",
  null
  
)

export default component.exports