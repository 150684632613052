import { render, staticRenderFns } from "./carry.vue?vue&type=template&id=497bf9d8&scoped=true&"
import script from "./carry.vue?vue&type=script&lang=js&"
export * from "./carry.vue?vue&type=script&lang=js&"
import style0 from "./carry.vue?vue&type=style&index=0&id=497bf9d8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@4.3.0_vue-template-compiler@2.6.12_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "497bf9d8",
  null
  
)

export default component.exports