import { render, staticRenderFns } from "./advantages.vue?vue&type=template&id=2b8759a4&scoped=true&"
import script from "./advantages.vue?vue&type=script&lang=js&"
export * from "./advantages.vue?vue&type=script&lang=js&"
import style0 from "./advantages.vue?vue&type=style&index=0&id=2b8759a4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@4.3.0_vue-template-compiler@2.6.12_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b8759a4",
  null
  
)

export default component.exports