import { render, staticRenderFns } from "./characteristics.vue?vue&type=template&id=51f15c07&scoped=true&"
import script from "./characteristics.vue?vue&type=script&lang=js&"
export * from "./characteristics.vue?vue&type=script&lang=js&"
import style0 from "./characteristics.vue?vue&type=style&index=0&id=51f15c07&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@4.3.0_vue-template-compiler@2.6.12_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51f15c07",
  null
  
)

export default component.exports