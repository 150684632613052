import "core-js/modules/es.array.iterator";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.iterator";
import flexAble from './utils/lib-flexable.js';
import utils from './utils/utils.js';
import './asset/common/common.css';
import 'normalize.css';
import 'animate.css';
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from './router/index.js';
import store from './store/index.js';
import App from './App.vue';
import topNav from '@/components/top-nav.vue';
import TitleSwitcher from '@/components/title-switcher.vue';
import stripTitle from '@/components/strip-title.vue';
import BlockTitle from '@/components/block-title/index.vue';
import myPartner from '@/components/my-partner.vue';
import myFooter from '@/components/footer/index.vue';
import ScrollToTop from '@/components/scroll-to-top.vue';
import FooterResolution from '@/components/footer/footer-resolution.vue';
import caseList from '@/components/case-list/index.vue';
import svgIcon from '@/components/svg-icon.vue';
import productList from '@/components/product-list.vue';
import introduceTabs from './components/introduce-list/introduce-tabs.vue';
import introduceTimeline from './components/introduce-list/introduce-timeline.vue';
Vue.component('top-nav', topNav);
Vue.component('TitleSwitcher', TitleSwitcher);
Vue.component('strip-title', stripTitle);
Vue.component('my-partner', myPartner);
Vue.component('my-footer', myFooter);
Vue.component('footer-resolution', FooterResolution);
Vue.component('scroll-to-top', ScrollToTop);
Vue.component('block-title', BlockTitle);
Vue.component('case-list', caseList);
Vue.component('svgIcon', svgIcon);
Vue.component('product-list', productList);
Vue.component('introduceTabs', introduceTabs);
Vue.component('introduceTimeline', introduceTimeline);
var requireAll = function requireAll(r) {
  return r.keys().map(r);
};
requireAll(require.context('@/asset/common/svg-icons', false, /\.svg$/));
window.utils = utils;
flexAble(window, window['lib'] || (window['lib'] = {}));
Vue.use(VueAxios, axios);
Vue.prototype.$axios = axios;
Vue.prototype.$bus = new Vue();
Vue.prototype.$chat = utils.onlineChat;
console.log('base_url: ', process.env.BASE_URL);
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  },
  mounted: function mounted() {
    document.dispatchEvent(new Event('render-event'));
  }
}).$mount('#root');